import { render, staticRenderFns } from "./table.vue?vue&type=template&id=f85d35ba&scoped=true"
import script from "./table.vue?vue&type=script&lang=js"
export * from "./table.vue?vue&type=script&lang=js"
import style0 from "./table.vue?vue&type=style&index=0&id=f85d35ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f85d35ba",
  null
  
)

export default component.exports