<template>
  <div class="tableContent">
    <div class="confirmationDialog"></div>
    <div id="messageDialog"></div>
    <div class="pb-2 tableHeaderStrip">
      <tableHeader
          :result="result" :search="search" :currentTable="currentTable"></tableHeader>
    </div>
    <v-card>
      <dynamicComponent
          :class="customClass"
          v-if="typeof result['headerQuickSearch'] != 'undefined'"
          :type="result['headerQuickSearch']"
          :result="result"
          :additional="{ currentTable: currentTable }"></dynamicComponent>

      <div id="viewArea">
        <dynamicComponent
            v-if="
            typeof currentTable['additionalViewingAreaTemplate'] != 'undefined'
          "
            :type="currentTable['additionalViewingAreaTemplate']"
            :result="result"
            :additional="{ currentTable: currentTable }"></dynamicComponent>

        <tableView
            :currentTable="currentTable" :search="search" :result="result" v-on="$listeners"></tableView>
      </div>

      <!-- <div
        id="overlay"
        style="display: none; position: absolute; background: #fff"
      >
        <img src="img/loading.gif" id="img-load" style="position: absolute" />
      </div> -->
    </v-card>
    <yes-no-dialog
        v-model="$store.state.tableDialog.active"
        v-if="$store.state.tableDialog.type !== 'Ok'"
        :title="$store.state.tableDialog.title"
        :text="$store.state.tableDialog.text"
        :width="$store.state.tableDialog.width"
        @yes="$store.state.tableDialog.yes"/>
    <OkDialog
        v-model="$store.state.tableDialog.active"
        v-else
        :title="$store.state.tableDialog.title"
        :text="$store.state.tableDialog.text"
        :width="$store.state.tableDialog.width"/>
    <AddField
        v-if="($route.fullPath === '/' || $route.fullPath.startsWith('/?f=testing&f2=configureAndRunTests'))
         && getURLSearchParam('testCaseId')"
        path="serve.php?f=testing&f2=configureAndRunTests"
        :additionalAttributes="{
        updateTable: false,
        function: 'getInsertedFieldsEdit',
        n_id: getURLSearchParam('testCaseId'),
        n_id_key: shaKey(getURLSearchParam('testCaseId')),
      }"
        :show-dialog="showDialog"
        :dialogAttributes="{}"
        v-on="$listeners"
        @dialog-closed="reloadTableContent"/>
  </div>
</template>
<script>
import dynamicComponent from '@/commonComponents/dynamicComponent.vue';
import tableHeader from '@/commonComponents/tableHeader.vue';

import tableView from '@/commonComponents/tableView.vue';
import {setAndUpdateTable} from '@/js/helper.js';
import YesNoDialog from '@/components/extended/YesNoDialog';
import OkDialog from '@/components/extended/OkDialog';
import AddField from '@/commonComponents/addField.vue';
import {shaKey} from '@/js/helper.js';
import requests from '@/js/requests';
import { getURLSearchParam } from '@/js/general';

export default {
  components: {
    AddField,
    OkDialog,
    YesNoDialog,
    tableHeader,

    tableView,
    dynamicComponent,
    //tableBottomControls
  },
  data() {
    return {
      // search: "",
      tablesData: this.$store.state.tablesData,
      getURLSearchParam: getURLSearchParam,
      intervalPointer: null,
      updateInProgress: false,
      triggerTime: 0,
      shaKey: shaKey,
      showDialog: false,
    };
  },
  watch: {
    'currentTable.reloadContent': function (newValue) {
      if (typeof newValue == 'undefined') {
        clearInterval(this.intervalPointer);
        //load for the last time
        this.reloadTableContent();
      } else {
        clearInterval(this.intervalPointer);
        this.intervalPointer = setTimeout(this.reloadTableContent, newValue);
      }
    },
  },
  created() {
    clearInterval(this.intervalPointer);
    if (
        typeof this.tablesData[this.result.table.id] == 'undefined' &&
        typeof this.result.table != 'undefined'
    ) {
      // this.tablesData[this.result.table.id] = this.result.table;
      this.$store.commit('tableChanged', this.result.table);
    }
    //  console.log(this.result["showGroupPage"]);
    // if (this.currentTable["reloadTable"] == true) {
    if (typeof this.currentTable['reloadContent'] != 'undefined') {
      var reloadInterval = this.currentTable['reloadContent'];
      let caller = this;
      this.intervalPointer = setTimeout(function () {
        caller.reloadTableContent();
      }, reloadInterval);
    }
    //  }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'tableChanged') {
        this.tablesData = state.tablesData;
      }
    });

    this.$nextTick(() => {
      if (getURLSearchParam('testCaseId')) {
        this.showDialog = true;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.intervalPointer);
  },
  computed: {
    customClass() {
      let className = '';
      if (typeof this.result['headerQuickSearch'] != 'undefined' && this.result['headerQuickSearch'] ==
          'testStatusLegend') {
        className = 'legendAdjust';
      }
      return className;
    },
    currentTable: {
      get() {
        return this.tablesData[this.result.table.id];
      },
      set(tableValue) {
        this.$emit('table-changed', tableValue);
      },
    },
    search() {
      return this.currentTable.searchValue;
    },
  },
  methods: {
    // updateSearch: function (search) {
    //   console.log(search, "change");
    //   this.search = search;
    // },

    reloadTableContent() {
      //  if (!this.updateInProgress) {
      if (typeof this.currentTable['reloadContent'] != 'undefined') {
        this.updateInProgress = true;
        let caller = this;
        let triggerTime = new Date();
        // check if a trigger was already sent to update the table from somewhere else
        if (
            typeof this.currentTable.triggerTime != 'undefined' &&
            this.currentTable.triggerTime.getTime() +
            this.currentTable.reloadContent >
            triggerTime.getTime()
        ) {
          let reloadInterval = caller.currentTable['reloadContent'];
          this.intervalPointer = setTimeout(function () {
            caller.reloadTableContent();
          }, reloadInterval);
        } else {
          this.$set(this.currentTable, 'triggerTime', triggerTime);
          this.triggerTime = triggerTime;
          console.log(this.currentTable.tableUrl);
          requests.frameworkAxiosRequest({
            method: 'post',
            url:
                typeof this.currentTable != 'undefined' &&
                typeof this.currentTable.tableUrl != 'undefined'
                    ? this.currentTable.tableUrl
                    : '',
            data: {
              function: 'getTableContent',
              requestType: 'ajax',
            },
          })
              .then(function (response) {
                //handle success

                // caller.setTableContentCallBack();

                if (
                    typeof response.data.result != 'undefined' &&
                    response.data.result.table.id == caller.result.table.id
                ) {
                  if (caller.triggerTime < caller.currentTable.triggerTime) {
                    clearTimeout(caller.intervalPointer);

                    let reloadInterval = caller.currentTable['reloadContent'];

                    caller.intervalPointer = setTimeout(function () {
                      caller.reloadTableContent();
                    }, reloadInterval);
                  } else {
                    setAndUpdateTable(
                        caller.currentTable,
                        response.data.result.table,
                        caller,
                    );
                    caller.$store.commit(
                        'tableChanged',
                        response.data.result.table,
                    );
                    clearTimeout(caller.intervalPointer);

                    if (
                        typeof response.data.result.table['reloadContent'] !=
                        'undefined' &&
                        response.data.result.table.id == caller.currentTable.id
                    ) {
                      let reloadInterval =
                          response.data.result.table['reloadContent'];

                      caller.intervalPointer = setTimeout(function () {
                        caller.reloadTableContent();
                      }, reloadInterval);
                    }
                  }
                }
                caller.updateInProgress = false;
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
        }
      }
      // }
    },

    setTableContentCallBack() {
      //$("#dataTable tbody").empty();
      //window.oTable.destroy();
      // var table = $tableSelector.data("dataTable");
      // table.clear();
      // addrows(data, $tableSelector, true);
      // if (selected.length > 0) {
      //   $.each(selected, function (index, value) {
      //     $tableSelector
      //       .find(".selectedElements:checkbox[value=" + value + "]")
      //       .attr("checked", true);
      //   });
      // }
      // $overlay.fadeOut();
    },
  },
  props: {result: {type: Object}},
};
</script>>
<style scoped>
.legendAdjust {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}
</style>