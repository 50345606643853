var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableContent"},[_c('div',{staticClass:"confirmationDialog"}),_c('div',{attrs:{"id":"messageDialog"}}),_c('div',{staticClass:"pb-2 tableHeaderStrip"},[_c('tableHeader',{attrs:{"result":_vm.result,"search":_vm.search,"currentTable":_vm.currentTable}})],1),_c('v-card',[(typeof _vm.result['headerQuickSearch'] != 'undefined')?_c('dynamicComponent',{class:_vm.customClass,attrs:{"type":_vm.result['headerQuickSearch'],"result":_vm.result,"additional":{ currentTable: _vm.currentTable }}}):_vm._e(),_c('div',{attrs:{"id":"viewArea"}},[(
          typeof _vm.currentTable['additionalViewingAreaTemplate'] != 'undefined'
        )?_c('dynamicComponent',{attrs:{"type":_vm.currentTable['additionalViewingAreaTemplate'],"result":_vm.result,"additional":{ currentTable: _vm.currentTable }}}):_vm._e(),_c('tableView',_vm._g({attrs:{"currentTable":_vm.currentTable,"search":_vm.search,"result":_vm.result}},_vm.$listeners))],1)],1),(_vm.$store.state.tableDialog.type !== 'Ok')?_c('yes-no-dialog',{attrs:{"title":_vm.$store.state.tableDialog.title,"text":_vm.$store.state.tableDialog.text,"width":_vm.$store.state.tableDialog.width},on:{"yes":_vm.$store.state.tableDialog.yes},model:{value:(_vm.$store.state.tableDialog.active),callback:function ($$v) {_vm.$set(_vm.$store.state.tableDialog, "active", $$v)},expression:"$store.state.tableDialog.active"}}):_c('OkDialog',{attrs:{"title":_vm.$store.state.tableDialog.title,"text":_vm.$store.state.tableDialog.text,"width":_vm.$store.state.tableDialog.width},model:{value:(_vm.$store.state.tableDialog.active),callback:function ($$v) {_vm.$set(_vm.$store.state.tableDialog, "active", $$v)},expression:"$store.state.tableDialog.active"}}),((_vm.$route.fullPath === '/' || _vm.$route.fullPath.startsWith('/?f=testing&f2=configureAndRunTests'))
       && _vm.getURLSearchParam('testCaseId'))?_c('AddField',_vm._g({attrs:{"path":"serve.php?f=testing&f2=configureAndRunTests","additionalAttributes":{
      updateTable: false,
      function: 'getInsertedFieldsEdit',
      n_id: _vm.getURLSearchParam('testCaseId'),
      n_id_key: _vm.shaKey(_vm.getURLSearchParam('testCaseId')),
    },"show-dialog":_vm.showDialog,"dialogAttributes":{}},on:{"dialog-closed":_vm.reloadTableContent}},_vm.$listeners)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }